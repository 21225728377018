import GraphQLInterfaceV1 from './GraphQLInterfaceV1';
import { GraphQLInterfaceV1Type } from './types';

let graphQLInterfaceV1: GraphQLInterfaceV1;

export default async function initializeGraphQLInterfaceV1(): Promise<GraphQLInterfaceV1Type> {
  if (graphQLInterfaceV1) return graphQLInterfaceV1.getInterface();
  graphQLInterfaceV1 = new GraphQLInterfaceV1();
  return graphQLInterfaceV1.getInterface();
}

export function createNoopGraphQLInterface(): GraphQLInterfaceV1Type {
  return {
    getClient: () => {
      console.debug('Function not implemented.');
      return null;
    },
    gql: null,
    reactTools: {
      createGraphQLProvider: () => {
        console.debug('Function not implemented.');
        return null;
      },
      useQuery: () => {
        console.debug('Function not implemented.');
        return null;
      }
    }
  };
}
