import { JarvisWebHttpClient } from '@jarvis/web-http';
import { ApplicationContext } from '@jarvis/jweb-core';
import { stackUrl, getBrowserInfoResult } from '../utils/enum';
import { Notifications } from '../notification';
import { AuthProvider, Stack } from '../../dataCollectionService/dataCollectionServicetypes';

export class TelemetryClient {
  apiKey: string | undefined;
  authProvider: AuthProvider | undefined;
  applicationContext: ApplicationContext;
  constructor(stack: Stack, applicationContext: ApplicationContext, authProvider?: AuthProvider, apiKey?: string) {

    this.jarvisWebHttpClient = new JarvisWebHttpClient({
      defaultBaseURL: `${stackUrl(stack)}/clienttelemetry/`,
      defaultAuthProvider: (authProvider && !apiKey) ? authProvider : undefined
    });
    this.apiKey = apiKey;
    this.authProvider = authProvider;
    this.applicationContext = applicationContext;
  }

  private queryParam = (trackingIdentifier: string | undefined) => {
    if (trackingIdentifier) return '?debug=true';
    return '';
  };
  API_VERSION = '3';
  SEND_EVENT_QUERY_STR_ENDPOINT = `v${this.API_VERSION}/telemetry/user`;
  SEND_EVENT_QUERY_STR_ENDPOINT_API_KEY = `v${this.API_VERSION}/telemetry/client`;

  result = getBrowserInfoResult();

  jarvisWebHttpClient: JarvisWebHttpClient;

  async sendEvent(notification: Notifications, trackingIdentifier?: string) {
    const authUrl = this.authProvider ? this.SEND_EVENT_QUERY_STR_ENDPOINT : '';
    return this.jarvisWebHttpClient.post({
      headers: {
        'Content-Type': 'application/json',
        'x-hp-client-id': this.apiKey ? this.apiKey : '',
        'X-HP-User-Agent': `${this.applicationContext?.webAppName}-${this.applicationContext?.webAppVersion};${this.result.os.name};${this.result.browser.version}`,
      },
      data: notification,
      url: this.apiKey
        ? `${this.SEND_EVENT_QUERY_STR_ENDPOINT_API_KEY}${this.queryParam(trackingIdentifier)}`
        : authUrl
    });
  }
}
