import { ApplicationContext, ValveControllerMetadata } from '@jarvis/jweb-core';
import { Notifications } from '../client/notification';
import getEventIdSuffix from '../helpers/getEventIdSuffix';
import { Queue } from './Queue';
export class QueueItem {
  // Queue will contain the Envelope
  constructor(
    metadata: ValveControllerMetadata,
    notification: Notifications,
    applicationContext: ApplicationContext,
    trackingIdentifier?: string,
    state?: string,
    attemptCount?: number,
    error?: string
  ) {
    this.state = state || 'default';
    this.attemptCount = attemptCount || 0;
    this.metadata = metadata;
    this.notification = notification;
    this.applicationContext = applicationContext;
    this.error = error;
    this.trackingIdentifier = trackingIdentifier;
  }
  id: number = parseInt(Date.now()+''+ getEventIdSuffix(),10);
  creationDate: string = new Date().toISOString();
  expirationDate: string = new Date(new Date().setHours(new Date().getHours() + Queue.queueItemTTLInHours)).toISOString();
  state = 'defaultState';
  attemptCount: number;
  metadata: ValveControllerMetadata;
  notification: Notifications;
  applicationContext: ApplicationContext;
  error?: string;
  trackingIdentifier?: string;
}
