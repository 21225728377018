import { Queue } from '../Queue/Queue';
import { QueueItem } from '../Queue/QueueItem';
import { QueueWorker } from '../Queue/QueueWorker';
import { logger } from '../helpers/logger';
import { getWindowValues } from '../client/utils/enum';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';

const setUpDB = (item?: QueueItem) => {
  if (!indexedDB) {
    logger.log('IndexDB::setUpDB::Your browser doesn\'t support a stable version of IndexedDB.');
  }

  const request = indexedDB.open('eventDataBase', 1);

  request.onerror = (event) => {
    logger.log('IndexDB::SetUpDB::error:', event);
  };

  request.onsuccess = async (event: any) => {
    EventDB.db = event.target.result;
    if (item) {
      await EventDB.add(item);
      Queue.enqueing = false;
      Queue.addToQueue();
    }
    const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
    const preConsentAccumlator = dataCollectionService.getConfiguration()?.preConsentEventAccumulation;
    if (!preConsentAccumlator || (webAppConsent !== 'undefined' && webAppConsent)){
      QueueWorker.startSendData();
    }
  };

  request.onupgradeneeded = (event: any) => {
    const db = event.target.result;
    db.createObjectStore('eventData', { keyPath: 'id' });
  };
};

setUpDB();

export class EventDB {
  static db: any;
  static getElement() {
    const promise = new Promise((res) => {
      try {
        const objectStore = EventDB.db.transaction('eventData').objectStore('eventData');
        objectStore.openCursor().onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) logger.log('IndexDB::eventDB::cursorKey',cursor.key);
          res(cursor);
        };
      } catch (e) {
        res(null);
      }
    });
    return promise;

  }

  static removeById(id: string) {
    const promise = new Promise(res => {
      try {
        const request = EventDB.db.transaction(['eventData'], 'readwrite').objectStore('eventData').delete(id);
        request.onsuccess = () => {
          logger.log(`IndexDB::eventDB::removeById:event ${id} is deleted successfully`);
          res('Data Deleted successfully');
        };
        request.onerror = () => {
          res('Unable to Delete data');
        };
      } catch (e) {
        res('Unable to Delete data');
      }
    });
    return promise;
  }

  static add = async (item: QueueItem) => {
    const promise = new Promise(res => {
      let request;
      try {
        request = EventDB.db.transaction(['eventData'], 'readwrite').objectStore('eventData').add(item);
        request.onsuccess = () => {
          logger.log('IndexDB::EventDB::add::Item Created with id:' + item.id,'&','Queue Worker Running?', QueueWorker.running);
          const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
          const preConsentEventAccumulation = dataCollectionService.getConfiguration()?.preConsentEventAccumulation;
          if (!QueueWorker.running && (!preConsentEventAccumulation || (webAppConsent !== 'undefined' && webAppConsent))) {
            logger.log('IndexDB::EventDB::add:starting the Queue Worker');
            QueueWorker.startSendData();
          }
          res('Item added');
        };
        request.onerror = () => {
          logger.log('IndexDB::EventDB::add::error:Unable to add to database! ');
        };
      } catch (e) {
        logger.log('IndexDB::EventDB::add::error:',e);
        setUpDB(item);
      }
    });
    return promise;
  };

  static count = () => {
    const promise: Promise<number> = new Promise(res => {
      try {
        const transaction = EventDB.db.transaction(['eventData'], 'readonly');
        const objectStore = transaction.objectStore('eventData');
        const countRequest = objectStore.count();
        countRequest.onsuccess = () => {
          logger.log('IndexDB::EventDB::count::CountRequest', countRequest.result);
          res(countRequest.result);
        };
      } catch (e) {
        return res(0);
      }
    });
    return promise;
  };

  static update = async (item: object, key: number) => {
    if (! await EventDB.isKeyPresent(key)) {
      logger.log('IndexDB::EventDB::update:item with this key is already deleted!');
      return;
    }
    const promise: Promise<boolean> = new Promise((res) => {
      try {
        const transaction = EventDB.db.transaction(['eventData'], 'readwrite');
        const objectStore = transaction.objectStore('eventData');
        logger.log('IndexDB::EventDB::update:updating queue Item');
        const updateRequest = objectStore.put(item);
        updateRequest.onsuccess = () => {
          logger.log('IndexDB::EventDB::update:Item updated');
          res(true);
        };
      } catch (e) {
        return res(false);
      }
    });
    return promise;
  };

  static isKeyPresent = (key: number) => {
    const promise: Promise<boolean> = new Promise(res => {
      try {
        const transaction = EventDB.db.transaction(['eventData'], 'readonly');
        const objectStore = transaction.objectStore('eventData');
        const getRequest = objectStore.get(key);
        getRequest.onsuccess = () => {
          res(!!getRequest.result);
        };
      } catch (e) {
        return res(false);
      }
    });
    return promise;
  };
}
