import { CDMObject, DataCollectionEventAction, DataCollectionEventResult, EventFailureReason, FilterCDMTreesOptions, FilterCDMTreesResult, ValveFilterError } from '@jarvis/jweb-core';
import { TelemetryClient } from '../client/TelemetryClient/TelemetryClient';
import { APIKeyConfiguration, AuthProviderConfiguration } from '../dataCollectionService/dataCollectionServicetypes';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { getWindowValues } from '../client/utils/enum';
import { logger } from '../helpers/logger';
import { Notifications } from '../client/notification';
import { validateSchema } from '../helpers/schemaValidation';
import { publishResultEventData } from '../helpers/publishResultEventData';
import { Queue } from './Queue';
import { QueueItem } from './QueueItem';
import { publishFilterError } from './queueHelpers';

export class QueueWorker {
  static running = false;
  static async startSendData() {
    if (QueueWorker.running) {
      return;
    }
    QueueWorker.running = true;

    let element: unknown = await Queue.peek();
    while (element) {
      element = await Queue.peek();
      if (!element) {
        logger.log('QueueWorker::startSendData:NO element in the Queue Stoping execution');
        QueueWorker.running = false;
        break;
      }
      let filterRequired = false;
      const elementValue: IDBCursorWithValue = (element as IDBCursorWithValue);
      for (const eventObject of elementValue.value.notification.events) {
        if (eventObject?.filter === undefined && eventObject?.filterError === undefined) {
          filterRequired = true;
          break;
        }
      }
      const configuration = dataCollectionService.getConfiguration();
      const trackingIdentifier = elementValue.value.trackingIdentifier;
      if (configuration) {
        logger.log(`QueueWorker::startSendData:queueSizeLimit ${Queue.queueSizeLimit}  publishRetries  ${Queue.publishRetries} queueItemTTLInHours ${Queue.queueItemTTLInHours} publishRetryDelay  ${Queue.publishRetryDelay}`);
        if (configuration?.preConsentEventAccumulation) {
          const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
          (elementValue.value.notification as Notifications).originator.originatorDetail.webAppConsent = webAppConsent;
        }
        let responseTime: number | undefined;
        let beginTime: number | undefined;
        let endTime: number;
        try {
          let filteredData: FilterCDMTreesResult | undefined = {
            results: [{ tree: '', treeGun: '' }]
          };
          if (filterRequired) {
            for (const eventObject of elementValue.value.notification.events) {
              eventObject?.filter !== undefined && delete eventObject['filter'];
              eventObject?.filterError !== undefined && delete eventObject['filterError'];
            }
            filteredData = await filterNotification(elementValue.value);
            if ((filteredData?.results[0] as ValveFilterError)?.errorType) {
              // delete the Element from the queue
              await publishFilterError(trackingIdentifier, elementValue.value.metadata, filteredData);
              logger.log('Got ValveFilterError! deleting the Item');
              await Queue.removeById(elementValue.key as string);
              continue;
            }
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.filterNotification, { valveControllerMetadata: elementValue.value.metadata, valveFilterResult: filteredData?.results });
          } else {
            // for prebuild Notification
            const cdmObject: CDMObject = {
              tree: JSON.stringify(elementValue.value.notification),
              treeGun: 'com.hp.cdm.service.eventing.version.1.resource.notification'
            };
            await publishResultEventData(
              trackingIdentifier,
              DataCollectionEventAction.filterNotification,
              {
                valveControllerMetadata: elementValue.value.metadata,
                valveFilterResult: 'Skipped due to preexisting filter operation'
              });
            filteredData.results = [cdmObject];
          }
          const filteredNotification = JSON.parse((filteredData?.results as unknown as CDMObject[])[0].tree);

          // send to telemetry
          const client = new TelemetryClient(configuration.stack, elementValue.value.applicationContext, (configuration as AuthProviderConfiguration).authProvider, (configuration as APIKeyConfiguration).telemetryAPIkey);
          logger.log('QueueWorker::startSendData::online?', getWindowValues().navigator.onLine);
          Queue.paused = !getWindowValues().navigator.onLine;
          if (Queue.paused) {
            logger.log('QueueWorker::startSendData:stoping the execution as Queue is paused due to no network');
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.finish, { result: DataCollectionEventResult.failure, message: 'Queue paused due to no network' });
            break;
          }
          // Schema validation for Envelope
          const validationResult = validateSchema(filteredNotification);
          if (!validationResult.valid) {
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.publishNotification, { telemetryServiceResponse: { reason: validationResult.errors.join(', ') } });
            logger.log('QueueWorker::startSendData::Schema Validation failed for Envelope:', validationResult.propertyPath, validationResult.errors.join());
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.finish, { result: DataCollectionEventResult.failure, message: `${EventFailureReason.localSchemaValidationFailure}:${validationResult.errors.join()}` });
            await Queue.removeById(elementValue.key as string);
            continue;
          }
          logger.log('QueueWorker::startSendData:Trying to send the event');

          beginTime = Date.now();
          const response = await client.sendEvent(filteredNotification, trackingIdentifier);
          endTime = Date.now();
          responseTime = endTime - beginTime;
          if (response.status >= 200 && response.status <= 299) {
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.publishNotification, undefined, response, responseTime);
            await publishResultEventData(trackingIdentifier, DataCollectionEventAction.finish, { result: (response.status === 206 ? DataCollectionEventResult.partialSuccess : DataCollectionEventResult.success), message: response.data });
            await Queue.removeById(elementValue.key as string);
          }
        } catch (err: any) {
          logger.log('QueueWorker::startSendData::error Message:', err.message);

          if (err.response?.status >= 400 && err.response?.status <= 499 ||
            err.response?.status >= 200 && err.response?.status <= 299) {
            // remove the element from the Queue for 4XX errrors, or 2XX successes - such as 206 partial success
            logger.log(`QueueWorker::startSendData::status:got status ${err.response.status} deleting the notification`);
            endTime = Date.now();
            if (endTime && beginTime) responseTime = endTime - beginTime;
            await publishResultEventData(
              trackingIdentifier,
              DataCollectionEventAction.publishNotification,
              undefined,
              err.response,
              responseTime);
            await Queue.removeById(elementValue.key as string);
            await publishResultEventData(
              trackingIdentifier,
              DataCollectionEventAction.finish,
              {
                result: DataCollectionEventResult.failure,
                message: err.response.statusText
              });
            continue;
          }
          logger.log('QueueWorker::startSendData::error:Server is out of service or some unknown error occured!');
          if (isExpired(elementValue.value.creationDate)) {
            // remove the element from the Queue
            await publishResultEventData(
              trackingIdentifier,
              DataCollectionEventAction.finish,
              {
                result: DataCollectionEventResult.failure,
                message: EventFailureReason.eventExpired
              }
            );
            await Queue.removeById(elementValue.key as string);
            continue;
          }
          if (elementValue.value.attemptCount >= Queue.publishRetries) {
            const updatedQueueItemValue = { ...elementValue.value, error: err.message, attemptCount: 0, state: 'failed' };
            await Queue.update(updatedQueueItemValue, elementValue.key as number);
            await publishResultEventData(
              trackingIdentifier,
              DataCollectionEventAction.finish,
              {
                result: DataCollectionEventResult.failure,
                message: EventFailureReason.queueRetryExceeded
              }
            );
            logger.log('QueueWorker::startSendData:Max retry attempt reached, Server is out of Service! stopping the execution');
            break;
          }
          // wait exponentially
          await wait(Math.pow(Queue.publishRetryDelay, elementValue.value.attemptCount + 1) * 1000);
          const updatedQueueItem = { ...elementValue.value, error: err.message, attemptCount: elementValue.value.attemptCount + 1, state: 'failed' };
          await Queue.update(updatedQueueItem, elementValue.key as number);
          continue;
        }
      } else {
        await publishResultEventData(
          trackingIdentifier,
          DataCollectionEventAction.finish,
          {
            result: DataCollectionEventResult.failure,
            message: 'Configuration not set'
          }
        );
        logger.log('Queue worker::startSendData:Stopping as configuration is not set');
        break;
      }
    }
    QueueWorker.running = false;
  }
}

const wait = (time: number) => new Promise(res => {
  setTimeout(() => {
    res('resolved');
  }, time);
});

const isExpired = (creationDateInISOString: string) => {
  const dateNow = new Date(Date.now());
  const creationDate = new Date(creationDateInISOString);
  const noOfHrs: number = (+dateNow - +creationDate) / 1000 / 3600;// es6
  return noOfHrs > Queue.queueItemTTLInHours;
};

export const filterNotification = async (queueItem: QueueItem) => {
  const cdmObject: CDMObject = {
    tree: JSON.stringify(queueItem.notification),
    treeGun: 'com.hp.cdm.service.eventing.version.1.resource.notification'
  };
  const filterCDMTreesOptions: FilterCDMTreesOptions = {
    cdmObjects: [cdmObject],
    filterMetadata: (queueItem as QueueItem).metadata
  };
  const filteredNotification = dataCollectionService.filterCDMTrees(filterCDMTreesOptions);
  return filteredNotification;
};
