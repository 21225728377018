export const governanceTags = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'description': 'Privacy Governance Schema - Promoted from 1.2.0-beta.1',

  'definitions': {
    'privacyDetail': {
      'title': 'Details about the privacy contract for the underlying data payload',
      'type': 'object',
      'properties': {
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1#/definitions/version'
        },
        'privacyBases': {
          'type': 'array',
          'maxItems': 8,
          'items': {
            'type': 'object',
            'properties': {
              'basis': {
                'type': 'string',
                'enum': [
                  'classic',
                  'hpOwned',
                  'appBasicDataCollection',
                  'printerBasicDataCollection',
                  'appAnalytics',
                  'printerAnalytics',
                  'appAdvertising',
                  'printerAdvertising',
                  'appPersonalized',
                  'printerPersonalized',
                  'webCookieBasicDataCollection',
                  'webCookieAdvertising',
                  'webCookiePersonalized',
                  'webCookieAnalytics',
                  'classicEnterprise'
                ]
              },
              'basisVersion': {
                'type': 'string',
                'pattern': '^\\d+\\.\\d+$'
              }
            },
            'required': [
              'basis',
              'basisVersion'
            ],
            'additionalProperties': false
          }
        },
        'dataPurposes': {
          'type': 'array',
          'maxItems': 6,
          'items': {
            'type': 'string',
            'enum': [
              'benefitsCustomer',
              'benefitsHp',
              'benefitsHpAnalytics',
              'benefitsHpAnalyticsAggregate',
              'benefitsHpPersonalized',
              'benefitsHpWithPet',
              'advertising',
              'limitedUseFlag'
            ]
          }
        }
      },
      'required': [
        'version',
        'privacyBases',
        'dataPurposes'
      ],
      'additionalProperties': false
    }
  },
  'gun': 'com.hp.cdm.domain.privacy.type.governanceTags.version.1',
  'version': '1.2.0',
  'jsonSchemaGun': 'com.hp.cdm.domain.privacy.type.governanceTags.version.1.schema.json',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.domain.privacy.type.governanceTags.version.1.schema.json'
};
