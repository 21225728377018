import {
  BuildNotificationResult,
  DataCollectionEventAction,
  DataCollectionEventNames,
  DataCollectionEventStatus,
  DataCollectionHTTPResponse,
  FilterNotificationResult,
  FinishResult,
  jWebReady,
  Publisher,
  PublishNotificationResult } from '@jarvis/jweb-core';

let publisher: Publisher;
export const publishResultEventData = async (
  trackingIdentifier: string|undefined,
  action: DataCollectionEventAction,
  result?: BuildNotificationResult
  | FilterNotificationResult
  | PublishNotificationResult
  | FinishResult,
  httpServiceResult?: any,
  responseTime?: number) => {

  let eventData: DataCollectionEventStatus;

  let telemetryServiceResponse: DataCollectionHTTPResponse | undefined;
  if (trackingIdentifier) {
    const eventService = (await jWebReady).Plugins?.EventService;
    if (eventService && !publisher){
      publisher = await eventService.createPublisher('com.hp.jarvis.data.collection.result.publisher') as Publisher;
    }

    eventData = {
      trackingIdentifier,
      action,
      actionDetail: result
    };
    if (action === DataCollectionEventAction.publishNotification) {
      if (httpServiceResult) {
        telemetryServiceResponse = {
          responseCode: httpServiceResult.status,
          responseSizeInBytes: new Blob([JSON.stringify(httpServiceResult)]).size,
          responseTimeInMs: responseTime || 0,
          headers: httpServiceResult.headers,
          body: httpServiceResult.data ? httpServiceResult.data : httpServiceResult.statusText
        };
      }
      eventData = {
        trackingIdentifier,
        action,
        actionDetail: (telemetryServiceResponse !== undefined ? { telemetryServiceResponse } : result)
      };
    }

    await (publisher as Publisher).publish(DataCollectionEventNames.cdmEventStatus, eventData);
  }
};
