import { gql } from '@jarvis-shell/graphql-shell-client';
import { Stack as ShellStack } from '../../types/stratus';
import { GraphQLService as ShellLibraryGraphQLService } from '@jarvis-shell/graphql-shell-client/dist/src/services/GraphQLService/GraphQLService';
import { GraphQLPlatform, GraphQLStack } from './enums';
import IGraphQLService from './IGraphQLService';
import { getServices } from '../../infra/commonInitializer';
import bindAllMethods from '../../utils/bindAllMethods';
import { GraphQLReactToolsType } from './types';
import { getJWeb } from '../JWeb';

class GraphQLService implements IGraphQLService {
  private _graphQLCoreMFePromise: Promise<any> | undefined;
  private _graphQLReactMFePromise: Promise<any> | undefined;
  private _graphQLClient: ShellLibraryGraphQLService;
  private _reactTools: GraphQLReactToolsType;
  public gql: typeof gql;

  public async init(): Promise<void> {
    bindAllMethods(this);

    const { authProviderService, applicationService } = getServices();
    await getJWeb().then((JWeb) => {
      // Doc  JWeb.platform;: https://pages.github.azc.ext.hp.com/jarvis/jweb/4.2.0/docs/jarvis-plugins#how-to-detect-if-a-web-app-is-running-inside-a-jarvis-webview-
      const jWebPlatform = JWeb?.platform;

      const shellStack = applicationService.getPortalStack();
      const isShellPortal = !!applicationService.getClientId();

      // TODO: We will need to deal with the non 'web' platform as soon as we have the library instantiated in the native side.
      const platform =
        GraphQLPlatform.WEB ||
        this._convertJWebPlatformToGraphQLPlatform(jWebPlatform);

      const stack = this._convertShellStackToGraphQLStack(shellStack);

      this._graphQLCoreMFePromise = System.import(
        '@jarvis-shell/graphql-shell-client'
      )
        .then(({ createGraphQLClient, gql }) => {
          const graphQLClient = createGraphQLClient({
            authTokenCallback: async () => {
              return authProviderService.getAccessToken();
            },
            platform,
            stack,
            isShellPortal,
            mock: true
          });

          this._graphQLClient = graphQLClient;
          this.gql = gql;
        })
        .catch(() => {
          console.error('Failed to load @jarvis-shell/graphql-shell-client');
        });

      this._graphQLReactMFePromise = System.import(
        '@jarvis-shell/graphql-shell-react'
      )
        .then(({ createGraphQLProvider, useQuery }) => {
          this._reactTools = { createGraphQLProvider, useQuery };
        })
        .catch(() => {
          console.error('Failed to load @jarvis-shell/graphql-shell-react');
        });
    });

    await Promise.all([
      this._graphQLCoreMFePromise,
      this._graphQLReactMFePromise
    ]);
  }

  public getReactTools = (): GraphQLReactToolsType => {
    return this._reactTools;
  };

  public getClient = (): ShellLibraryGraphQLService => {
    return this._graphQLClient;
  };

  private _convertShellStackToGraphQLStack(
    shellStack: ShellStack
  ): GraphQLStack {
    switch (shellStack) {
      case ShellStack.dev:
        return GraphQLStack.DEV;
      case ShellStack.pie:
        return GraphQLStack.PIE;
      case ShellStack.stage:
        return GraphQLStack.STAGE;
      default:
        return GraphQLStack.PROD;
    }
  }

  private _convertJWebPlatformToGraphQLPlatform(
    jWebPlatform: string
  ): GraphQLPlatform {
    switch (jWebPlatform) {
      case 'ios':
        return GraphQLPlatform.IOS;
      case 'mac':
        return GraphQLPlatform.MAC;
      case 'windows':
        return GraphQLPlatform.WINDOWS;
      case 'android':
        return GraphQLPlatform.ANDROID;
      default:
        return GraphQLPlatform.WEB;
    }
  }
}

export default GraphQLService;
