import { ApplicationContext, BuildNotificationResult, CDMEventData, DataCollectionEventAction, DataCollectionEventResult, DataValveCDMEventData, ValveControllerMetadata } from '@jarvis/jweb-core';
import { getPlatform, getWindowValues } from '../client/utils/enum';
import { buildNotification } from '../client/buildNotification';
import { ControlledData } from '../client/interfaces/controlledData';
import { Event } from '../client/event/Event';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { BrowserInfoEventDetail } from '../client/event/EventDetail';
import { Queue } from '../Queue/Queue';
import { QueueItem } from '../Queue/QueueItem';
import { logger } from '../helpers/logger';
import { Notifications } from '../client/notification';
import { getApplicationContextFromValueStore, getValveControllerMetadataFromValueStore } from './valueStoreHelpers';
import { publishResultEventData } from './publishResultEventData';

const buildEnvelopAndSendNotification = async (eventInfoeventData: DataValveCDMEventData) => {
  const eventData = eventInfoeventData;
  const cdmEvents = eventData?.events === undefined ? undefined:eventData.events as [CDMEventData] ;
  const preBuildNotifications = eventData?.notifications === undefined ? undefined : (eventData.notifications.length?eventData.notifications.map(preBuildNotification => JSON.parse(JSON.stringify(preBuildNotification))) : undefined);
  const metadata = eventData.valveControllerMetadata as ValveControllerMetadata;
  const applicationContext = await getApplicationContextFromValueStore(eventData.applicationContext as ApplicationContext);
  const trackingIdentifier = eventData?.trackingIdentifier;
  const windowValue = getWindowValues();
  const previousMetadata = windowValue.sessionStorage.getItem('ValveControllerMetadata');
  const previousVisitUuid = windowValue.sessionStorage.getItem('visitUuid');
  const previousWebAppConsent = windowValue.sessionStorage.getItem('webAppConsent');
  logger.log('BuildEnvelopeAndSendNotification::CDMEvents:', cdmEvents);
  logger.log('BuildEnvelopeAndSendNotification::Metadata:', metadata);
  const configuration = dataCollectionService.getConfiguration();
  const filterMetaData = await getValveControllerMetadataFromValueStore(metadata);
  if (configuration) {
    const controlledData: ControlledData = {
      associatedDeviceUuid: filterMetaData?.deviceId,
      associatedDeviceProductNumber: filterMetaData?.edgeType,
      associatedStratusUserId: filterMetaData?.accountLoginId,
      ucdeCorrelationId: applicationContext?.ucdeCorrelationId,
      webAppVersion: applicationContext?.webAppVersion,
      visitorUuid: applicationContext?.visitorUuid,
      visitUuid: applicationContext?.visitUuid,
      webAppConsent: applicationContext?.webAppConsent,
      osCountryRegion: windowValue.osCountryRegion,
      osLanguage: windowValue.osLanguage,
      osPlatform: getPlatform(),
      webAppName: applicationContext?.webAppName,
      browserUserAgent: windowValue.browserUserAgent,
      browserScreenResolution: windowValue.browserScreenResolution
    };
    const events = [];
    let notification;
    if (cdmEvents !== undefined){

      for (const cdmEvent of cdmEvents) {
        const event = new Event(cdmEvent.dateTime, cdmEvent.eventCategory, cdmEvent.eventDetailType, cdmEvent.eventDetail);
        events.push(event);
      }
      if (JSON.stringify(metadata) !== previousMetadata ||
          applicationContext.visitUuid !== previousVisitUuid ||
          (applicationContext.webAppConsent !== previousWebAppConsent)
      ) {
        const BROWSER_INFO_DETAIL_TYPE = 'com.hp.cdm.domain.telemetry.type.eventDetail.category.browserInformation.version.1';
        const browserInfoEvent = new Event(new Date().toISOString(), 'browserInformation', BROWSER_INFO_DETAIL_TYPE, new BrowserInfoEventDetail());
        events.push(browserInfoEvent);
        windowValue.sessionStorage.setItem('ValveControllerMetadata', JSON.stringify(metadata));
        windowValue.sessionStorage.setItem('visitUuid', applicationContext.visitUuid);
        windowValue.sessionStorage.setItem('webAppConsent', applicationContext.webAppConsent);
      }
      notification = buildNotification(controlledData, events);
      const queueItem = new QueueItem(filterMetaData, notification, applicationContext, trackingIdentifier);
      await publishResultEventData(trackingIdentifier, DataCollectionEventAction.buildNotification, { notification, preBuilt:false } as BuildNotificationResult);
      logger.log('BuildEnvelopeAndSendNotification:notification::',notification);
      Queue.enqueue(queueItem);
    }

    // check for existence of pre-built CDM notifications
    if (preBuildNotifications !== undefined){
      for (const preBuildNotification of preBuildNotifications) {
        const notificationObject = preBuildNotification as Notifications;
        const queueItem = new QueueItem(filterMetaData,notificationObject , applicationContext, trackingIdentifier);
        await publishResultEventData(trackingIdentifier, DataCollectionEventAction.buildNotification, { notification:notificationObject, preBuilt:true } as BuildNotificationResult);
        logger.log('BuildEnvelopeAndSendNotification:preBuildNotification::',preBuildNotification);
        Queue.enqueue(queueItem);
      }
    }
    if (preBuildNotifications === undefined && cdmEvents === undefined){
      await publishResultEventData(
        trackingIdentifier,
        DataCollectionEventAction.finish,
        { result: DataCollectionEventResult.failure,
          message: 'both are empty Please Provide atleast one option' });
    }
  }
};

export default buildEnvelopAndSendNotification;


// show error if both even and notification is not there
