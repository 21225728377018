import 'reflect-metadata';
import { container } from 'tsyringe';
import { GetAccessTokenService } from '../../services/authTokenService/getAcessTokenService';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { RepositoriesType } from './RepositoriesType';
import AppContext from '../../services/appContext/AppContext';
import {
  OnecloudApplicationService,
  StratusApplicationService
} from '../../services/applicationService';
import { AuthProviderService } from '../../services/authProviderService';
import { AuthTokenService } from '../../services/authTokenService';
import BreadcrumbService from '../../services/breadcrumbService';
import { WebCryptoApiEncryptService } from '../../services/encryptService';
import EventService from '../../services/eventService';
import { LaunchDarklyFeatureFlagService } from '../../services/featureFlagService';
import { SplunkRumService } from '../../services/monitoringService';
import { ScopeService } from '../../services/scope';
import {
  SessionServiceNative,
  SessionServiceWeb
} from '../../services/session';
import {
  LoginServiceWeb,
  LoginServiceNative
} from '../../services/session/loginService';
import {
  ShellLogoutService,
  StratusLogoutService,
  OnecloudLogoutService
} from '../../services/session/logoutService';
import {
  ISupportSessionService,
  SupportSessionService
} from '../../services/supportSession';
import { CommonsInitializeType, SetServiceDependencies } from './types';
import {
  OnecloudUserOnboardingService,
  StratusUserOnboardingService
} from '../../services/userOnboardingService';
import { StratusUserService } from '../../services/userService';
import { ServicesType } from './ServicesType';
import WebServiceRouting from '../../services/webServiceRouting';
import { RoutesService } from '../../services/RoutesService';
import { NavigationService } from '../../services/navigationService';
import StateParamHandlerService from '../../services/stateParamHandlerService/StateParamHandlerService';
import { ThemeService } from '../../services/themeService';
import { ServiceWorkerService } from '../../services/serviceWorkerService';
import { AnalyticsService } from '../../services/AnalyticsService';
import {
  LocalizationService,
  LocalizationTranslatorService
} from '../../services/localizationService';
import { ConsentService } from '../../services/consentService';
import { IdleService } from '../../services/IdleService';
import OnecloudUserService from '../../services/userService/OnecloudUserService';
import { BackgroundTaskService } from '../../services/backgroundTaskService/backgroundTask';
import { SystemJSAssetLoaderService } from '../../services/backgroundTaskService/assetLoader';
import { BackgroundTaskManagerService } from '../../services/backgroundTaskService';
import { GrantService } from '../../services/grants/GrantService';
import RefreshTokenServiceWeb from '../../services/session/refreshTokenService/RefreshTokenServiceWeb';
import { TenantHandlerService } from '../../services/tenantHandler';
import RefreshTokenServiceNative from '../../services/session/refreshTokenService/RefreshTokenServiceNative';
import { URLService } from '../../services/URLService';
import { LayoutsService } from '../../services/LayoutsService';
import GraphQLService from '../../services/graphQLService/GraphQLService';

export type ServicesSetDependenciesParams = {
  repositories: RepositoriesType;
};

export default class ServicesInitializer {
  public static async instantiate(
    params: CommonsInitializeType
  ): Promise<ServicesType> {
    const {
      stack,
      clientId,
      appName,
      localization,
      analytics,
      consent,
      isNative,
      theme,
      login,
      defaultLayoutKey,
      backgroundTasks,
      routes,
      featureFlags,
      tenantHandlerProps,
      monitoring,
      serviceWorker,
      userActivity,
      urlService,
      grants,
      layouts
    } = params;

    const services = {} as ServicesType;

    // Contextless services

    services.graphQLService = new GraphQLService();

    services.URLService = new URLService(urlService);

    services.layoutsService = new LayoutsService({
      defaultLayoutKey,
      layoutList: layouts
    });

    services.eventService = new EventService();

    services.appContext = new AppContext();

    services.authProviderService = new AuthProviderService();

    services.tenantHandlerService = container.resolve(TenantHandlerService);

    // SUPPORT SESSION INITIALIZER
    const supportSessionService: ISupportSessionService = container.resolve(
      SupportSessionService
    );

    // TODO: think about this
    //Disables tenantHandler and orgSelector when support session is enabled
    if (supportSessionService.isSupportSession()) {
      if (tenantHandlerProps) {
        tenantHandlerProps.enabled = false;
      }
    }
    services.supportSessionService = supportSessionService;

    services.authTokenService = container.resolve(AuthTokenService);

    services.breadcrumbService = new BreadcrumbService();

    services.backgroundTaskService = new BackgroundTaskService();
    services.assetLoaderService = new SystemJSAssetLoaderService();

    services.backgroundTaskManagerService = new BackgroundTaskManagerService(
      backgroundTasks
    );

    services.encryptService = new WebCryptoApiEncryptService();

    services.localizationService = new LocalizationService({
      localization
    });

    services.localizationTranslatorService = new LocalizationTranslatorService({
      localization
    });

    services.localizationTranslatorService = new LocalizationTranslatorService({
      localization
    });

    services.featureFlagService = new LaunchDarklyFeatureFlagService(
      featureFlags
    );

    services.monitoringService = new SplunkRumService(monitoring);
    services.navigationService = new NavigationService();

    services.routesService = new RoutesService(routes);

    services.scopeService = new ScopeService();

    services.themeService = new ThemeService(theme);

    services.consentService = new ConsentService(consent);

    if (login.authenticationProvider === AuthenticationProviderEnum.coptor) {
      services.userService = new OnecloudUserService();
    } else {
      services.userService = new StratusUserService();
    }

    services.webServiceRouting = new WebServiceRouting();

    if (login.authenticationProvider === AuthenticationProviderEnum.coptor) {
      // Onecloud only services
      services.applicationService = new OnecloudApplicationService({
        portalStack: stack,
        clientId,
        appName
      });

      services.userOnboardingService = new OnecloudUserOnboardingService();
    } else {
      // Stratus only Services
      services.applicationService = new StratusApplicationService({
        portalStack: stack,
        clientId,
        appName
      });
      services.userOnboardingService = new StratusUserOnboardingService();
    }

    // TODO: Check if it's native.
    if (isNative) {
      services.loginService = new LoginServiceNative();
      services.sessionService = container.resolve(SessionServiceNative);
    } else {
      services.loginService = new LoginServiceWeb();
      services.sessionService = container.resolve(SessionServiceWeb);
    }

    // Analytics Service
    if (analytics.enabled) {
      services.analyticsService = new AnalyticsService(analytics);
    }

    // Logout Service
    if (services.supportSessionService?.isSupportSession()) {
      services.logoutService = new ShellLogoutService();
    } else if (
      login.authenticationProvider === AuthenticationProviderEnum.coptor
    ) {
      services.logoutService = new OnecloudLogoutService();
    } else {
      services.logoutService = new StratusLogoutService();
    }

    services.stateParamHandlerService = new StateParamHandlerService();
    services.serviceWorkerService = new ServiceWorkerService(serviceWorker);
    services.idleService = new IdleService(userActivity);
    services.grantService = new GrantService(grants);
    return services;
  }

  public static async resolveDependencies({
    services,
    repositories,
    clients
  }: SetServiceDependencies): Promise<ServicesType> {
    const defaultDependencies: SetServiceDependencies = {
      services: services,
      repositories,
      clients
    };

    services?.analyticsService?.setDependencies(defaultDependencies);
    services?.authProviderService?.setDependencies(defaultDependencies);

    services?.breadcrumbService?.setDependencies(defaultDependencies);
    services?.featureFlagService?.setDependencies(defaultDependencies);
    services?.scopeService?.setDependencies(defaultDependencies);
    services?.sessionService?.setDependencies(defaultDependencies);
    services?.loginService?.setDependencies(defaultDependencies);
    services?.logoutService?.setDependencies(defaultDependencies);
    services?.tenantHandlerService?.setDependencies(defaultDependencies);
    services?.userOnboardingService?.setDependencies(defaultDependencies);
    services?.userService?.setDependencies(defaultDependencies);
    services?.webServiceRouting?.setDependencies(defaultDependencies);
    services?.consentService?.setDependencies(defaultDependencies);
    services?.localizationTranslatorService?.setDependencies(
      defaultDependencies
    );
    services?.localizationService?.setDependencies(defaultDependencies);
    services?.navigationService?.setDependencies(defaultDependencies);
    services?.serviceWorkerService?.setDependencies(defaultDependencies);
    services?.backgroundTaskManagerService?.setDependencies(
      defaultDependencies
    );
    services?.grantService?.setDependencies(defaultDependencies);
    services?.stateParamHandlerService?.setDependencies(defaultDependencies);

    return services;
  }

  // It only registers the default resource in case there is no custom override.
  private static _registerIfNotOverriden(key: string, service): void {
    if (!container.isRegistered(key)) {
      container.registerSingleton(key, service);
    }
  }

  public static registerOverridableSingletons(): void {
    this._registerIfNotOverriden(
      'IGetAccessTokenService',
      GetAccessTokenService
    );
    if (!container.isRegistered('IRefreshTokenService')) {
      container.register('IRefreshTokenService', {
        useFactory: () => {
          const isNative = container.resolve('IsNative');
          return isNative
            ? container.resolve(RefreshTokenServiceNative)
            : container.resolve(RefreshTokenServiceWeb);
        }
      });
    }
  }
}
