import { DataCollectionEventAction, DataCollectionEventResult, FilterCDMTreesResult, FilterNotificationResult, ValveControllerMetadata, ValveFilterError } from '@jarvis/jweb-core';
import { logger } from '../helpers/logger';
import { publishResultEventData } from '../helpers/publishResultEventData';

export const publishFilterError = async (trackingIdentifier: string | undefined, metadata: ValveControllerMetadata, filteredData: FilterCDMTreesResult) => {
  await publishResultEventData(
    trackingIdentifier,
    DataCollectionEventAction.filterNotification,
    {
      valveControllerMetadata: metadata,
      valveFilterResult: filteredData.results
    } as FilterNotificationResult);
  await publishResultEventData(
    trackingIdentifier,
    DataCollectionEventAction.finish,
    {
      result: DataCollectionEventResult.failure,
      message: (filteredData.results[0] as ValveFilterError)?.errorType
    });
};

export const publishTelemetryError = async (err: any, trackingIdentifier: string | undefined, beginTime: number | undefined) => {
  let responseTime: number | undefined;
  if (beginTime) responseTime = Date.now() - beginTime;
  if (err.response?.status >= 400 && err.response?.status <= 499 ||
    err.response?.status >= 200 && err.response?.status <= 299) {
    // remove the element from the Queue for 4XX errrors, or 2XX successes - such as 206 partial success
    logger.log(`Queue::addToQueue::status:got status ${err.response.status}`);
    await publishResultEventData(
      trackingIdentifier,
      DataCollectionEventAction.publishNotification,
      undefined,
      err.response,
      responseTime);

    await publishResultEventData(
      trackingIdentifier,
      DataCollectionEventAction.finish,
      {
        result: DataCollectionEventResult.failure,
        message: err.response.statusText
      });
  } else {
    logger.log('Queue::addToQueue::error:Server is out of service or some unknown error occured!');
    await publishResultEventData(
      trackingIdentifier,
      DataCollectionEventAction.publishNotification,
      {
        result: DataCollectionEventResult.failure,
        message: err.message
      });
    await publishResultEventData(
      trackingIdentifier,
      DataCollectionEventAction.finish,
      {
        result: DataCollectionEventResult.failure,
        message: err.message
      });
  }
};
