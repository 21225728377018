import bindAllMethods from '../../../utils/bindAllMethods';
import { getServices } from '../../../infra/commonInitializer';
import { GraphQLInterfaceV1Type as GraphQLInterfaceV1Type } from './types';
import IGraphQLService from '../../../services/graphQLService/IGraphQLService';

class GraphQLInterfaceV1 {
  private _graphQLService: IGraphQLService;

  constructor() {
    const { graphQLService } = getServices();
    this._graphQLService = graphQLService;
    bindAllMethods(this);
  }

  public getInterface(): GraphQLInterfaceV1Type {
    const { gql, getClient, getReactTools } = this._graphQLService;
    const { createGraphQLProvider, useQuery } = getReactTools();
    return {
      gql,
      getClient,
      reactTools: { createGraphQLProvider, useQuery }
    };
  }
}

export default GraphQLInterfaceV1;
