export type Configuration = AuthProviderConfiguration | APIKeyConfiguration;
export interface BaseConfiguration {
  stack: Stack;
  dataIngressCustomEndpoint?: string;
  dataValveCustomEndpoint?: string;
  verboseLogging?: boolean;
  queueConfiguration?: QueueConfiguration;
  useValveControllerFiltering: boolean;
  useOneTrustFiltering: boolean;
  preConsentEventAccumulation?: boolean;
}
export interface QueueConfiguration {
  publishRetries?: number;
  publishRetryDelay?: number;
  queueItemTTLInHours?: number;
  queueSizeLimit?: number;
}
export interface AuthProviderConfiguration extends BaseConfiguration {
  authProvider: AuthProvider;
}
export interface APIKeyConfiguration extends BaseConfiguration {
  telemetryAPIkey: string;
  valveControllerAPIkey: string;
  cacheControl: string;
}

export interface ConfigurationProvider {
  /**
   *
   * @return the configuration object
   */
  configuration: Configuration;
}

export enum Stack {
  local,
  dev,
  pie,
  staging,
  production,
  custom
}

export interface AuthProvider {
  getAccessToken: (forceRefresh?: boolean) => Promise<string>;
}

export enum QueueDefaults {
  PUBLISH_RETRIES = 3,
  PUBLISH_RETRY_DELAY = 2,
  QUEUE_ITEM_TTL_IN_HOURS = 24,
  QUEUE_SIZE_LIMIT = 1000
}
